import actions from "./actions";

const initState = {
    planResult: [],
    planData: [],
    planRegion: [],
    planDay: [],
    loading: false,
    buttonLoading: false,
    getPlanLoading: false,
    planError: null,
    planMessage: null,
    buttonloading: false,
    planFormModal: false,
    cronusInventoryData: [],
    trafficPolicyResult: [],
    packageView: false,
    planViewModal: false,
    planViewModalData: [],
    getEsimGoSkuList: [],
    getEsimGoSkuLoading: false,
    getEsimGoSkuError: null,
    trafficPolicyResultActive: [],

    getSimplyEliteSkuLoading: false,
    getSimplyEliteSkuError: null,
    getSimplyEliteSkuList: [],
};

export default function reducer(state = initState, action) {
    switch (action.type) {

        case actions.GET_SIMPLY_ELITE_SKU:
            return {
                ...state,
                getSimplyEliteSkuLoading: true,
                getSimplyEliteSkuError: null,
                getSimplyEliteSkuList: [],
            };
        case actions.GET_SIMPLY_ELITE_SKU_SUCCESS:
            return {
                ...state,
                getSimplyEliteSkuLoading: false,
                getSimplyEliteSkuError: null,
                getSimplyEliteSkuList: action.payload.data,
            };
        case actions.GET_SIMPLY_ELITE_SKU_FAILED:
            return {
                ...state,
                getSimplyEliteSkuLoading: false,
                getSimplyEliteSkuError: action.payload.error,
                getSimplyEliteSkuList: [],
            };



        case actions.GET_ESIM_GO_SKU:
            return {
                ...state,
                getEsimGoSkuList: [],
                getEsimGoSkuLoading: true,
                getEsimGoSkuError: null,
            };
        case actions.GET_ESIM_GO_SKU_SUCCESS:
            return {
                ...state,
                getEsimGoSkuList: action.payload.data,
                getEsimGoSkuLoading: true,
                getEsimGoSkuError: null,
            };
        case actions.GET_ESIM_GO_SKU_FAILED:
            return {
                ...state,
                getEsimGoSkuList: [],
                getEsimGoSkuLoading: false,
                getEsimGoSkuError: action.payload.error,
            };


        case actions.OPEN_PLAN_MODAL:
            state.planResult.map((item) => {
                let temp = item;
                if (temp.ID == action.payload.planID) {
                    temp.loading = !temp.loading;
                }
                return temp;
            });

            return {
                ...state,
            };
        case actions.OPEN_PLAN_MODAL_SUCCESS:
            state.planResult.map((item) => {
                let temp = item;
                if (temp.ID == action.planID) {
                    temp.loading = !temp.loading;
                }
                return temp;
            });

            return {
                ...state,
                planViewModal: true,
                planViewModalData: action.data
            };

        case actions.CLOSE_PLAN_MODAL:
            return {
                ...state,
                planViewModal: false,
            };

        case actions.GET_PLAN:
            return {
                ...state,
                loading: true,
                buttonLoading: false,
                planMessage: null,
                planError: null,
                buttonloading: false,
                planFormModal: false,
            };
        case actions.GET_PLAN_SUCCESS:
            return {
                ...state,
                loading: false,
                buttonLoading: false,
                planResult: action.planResult,
                planMessage: null,
                planError: null,
                buttonloading: false,
                planFormModal: false,
            };
        case actions.GET_PLAN_FAILED:
            return {
                ...state,
                loading: false,
                buttonLoading: false,
                planError: action.planError,
                planMessage: null,
                buttonloading: false,
                planFormModal: false,
            };
        case actions.ADD_PLAN:
            return {
                ...state,
                loading: false,
                buttonLoading: true,
                planMessage: null,
                planError: null,
                buttonloading: true,
                planFormModal: true,
            };
        case actions.ADD_PLAN_SUCCESS:
            return {
                ...state,
                loading: false,
                buttonLoading: false,
                planError: null,
                planMessage: action.planMessage,
                planResult: action.planResult,
                buttonloading: false,
                planFormModal: false,
            };
        case actions.ADD_PLAN_FAILED:
            return {
                ...state,
                loading: false,
                buttonLoading: false,
                planError: action.planError,
                planMessage: null,
                buttonloading: false,
                planFormModal: true,
            };

        case actions.DELETE_PLAN:
            return {
                ...state,
                loading: false,
                planMessage: null,
                planError: null,
                buttonloading: true,
                planFormModal: false,
            };
        case actions.DELETE_PLAN_SUCCESS:
            return {
                ...state,
                loading: false,
                planError: null,
                planMessage: action.planMessage,
                planResult: action.planResult,
                buttonloading: false,
                planFormModal: false,
            };
        case actions.PREPARE_PLAN_FORM:
            return {
                ...state,
                loading: false,
                planError: null,
                planMessage: null,
                buttonloading: false,
                planFormModal: true,
            };
        case actions.RESET_PLAN:
            return {
                ...state,
                loading: false,
                planError: null,
                planMessage: null,
                buttonloading: false,
                planFormModal: false,
            };
        case actions.GET_PLAN_DATA:
            return {
                ...state,
                getPlanLoading: true,
                planError: null,
                planMessage: null,
                buttonloading: true,
                planFormModal: false,
            };
        case actions.GET_PLAN_DATA_SUCCESS:
            return {
                ...state,
                getPlanLoading: false,
                planError: null,
                planMessage: null,
                buttonloading: false,
                planFormModal: false,
                planData: action.planData,
            };
        case actions.GET_PLAN_DATA_FAILED:
            return {
                ...state,
                getPlanLoading: false,
                planError: action.planError,
                planMessage: null,
                buttonloading: false,
                planFormModal: false,
            }
        case actions.GET_PLAN_DAY:
            return {
                ...state,
                getPlanLoading: true,
                planError: null,
                planMessage: null,
                buttonloading: true,
                planFormModal: false,
            };
        case actions.GET_PLAN_DAY_SUCCESS:
            return {
                ...state,
                getPlanLoading: false,
                planError: null,
                planMessage: null,
                buttonloading: false,
                planFormModal: false,
                planDay: action.planDayData,
            };
        case actions.GET_PLAN_DAY_FAILED:
            return {
                ...state,
                getPlanLoading: false,
                planError: action.planError,
                planMessage: null,
                buttonloading: false,
                planFormModal: false,
            }
        case actions.GET_PLAN_REGION:
            return {
                ...state,
                getPlanLoading: true,
                planError: null,
                planMessage: null,
                buttonloading: true,
                planFormModal: false,
            };
        case actions.GET_PLAN_REGION_SUCCESS:
            return {
                ...state,
                getPlanLoading: false,
                planError: null,
                planMessage: null,
                buttonloading: false,
                planFormModal: false,
                planRegion: action.planRegionData,
            };
        case actions.GET_PLAN_REGION_FAILED:
            return {
                ...state,
                loading: false,
                getPlanLoading: false,
                planError: action.planError,
                planMessage: null,
                buttonloading: false,
                planFormModal: false,
            }
        case actions.RESET_PLAN_MESSAGE:
            return {
                ...state,
                loading: false,
                planError: null,
                planMessage: null,
                buttonloading: false,
            };
        case actions.ADD_PLAN_TO_CART:
            return {
                ...state,
                planError: null,
                planMessage: null,
                buttonloading: true,
            };
        case actions.ADD_PLAN_TO_CART_SUCCESSFULL:
            return {
                ...state,
                planError: null,
                planMessage: action.planMessage,
                addToCartData: action.addToCartData,
                buttonloading: false,
            };
        case actions.ADD_PLAN_TO_CART_FAILED:
            return {
                ...state,
                planMessage: null,
                planError: action.planMessage,
                addToCartData: action.addToCartData,
                buttonloading: false,
            };
        case actions.CHECK_CART_DATA:
            return {
                ...state,
                planError: null,
                planMessage: null
            };
        case actions.CHECK_CART_DATA_SUCCESSFULL:
            return {
                ...state,
                planError: null,
                planMessage: null,
                checkCartFlag: action.checkCartFlag,
            };
        case actions.CHECK_CART_DATA_FAILED:
            return {
                ...state,
                planError: action.planMessage,
                buttonloading: false,
            };
        case actions.GET_CRONUS_INVENTORY_DATA:
            return {
                ...state,
                planError: null,
                planMessage: null,
            };
        case actions.GET_CRONUS_INVENTORY_DATA_SUCCESS:
            return {
                ...state,
                planError: null,
                planMessage: null,
                cronusInventoryData: action.cronusInventoryData
            };
        case actions.GET_CRONUS_INVENTORY_DATA_FAILED:
            return {
                ...state,
                planError: action.planError,
                planMessage: null
            };
        case actions.GET_TRAFFIC_POLICY:
            return {
                ...state,
                planError: null,
                planMessage: null,
            };
        case actions.GET_TRAFFIC_POLICY_SUCCESS:
            return {
                ...state,
                planError: null,
                planMessage: null,
                trafficPolicyResult: action.trafficPolicyResult
            };
        case actions.GET_TRAFFIC_POLICY_FAILED:
            return {
                ...state,
                planError: action.planError,
                planMessage: null,
            };
        case actions.CREATE_PLAN_PACKAGE:
            return {
                ...state,
                planError: null,
                buttonLoading: true,
                planMessage: null,
            };
        case actions.CREATE_PLAN_PACKAGE_SUCCESS:
            return {
                ...state,
                planError: null,
                buttonLoading: false,
                planMessage: action.packagePlanResult,
                planResult: action.planResult,
                packageView: false
            };
        case actions.CREATE_PLAN_PACKAGE_FAILED:
            return {
                ...state,
                planError: action.planError,
                planMessage: null,
                buttonLoading: false,
                packageView: true
            };

        case actions.CHECK_PLAN_NAME:
            return {
                ...state,
                planError: null,
                planMessage: null,
            };
        case actions.CHECK_PLAN_NAME_SUCCESS:
            return {
                ...state,
                planError: null,
            };
        case actions.CHECK_PLAN_NAME_FAILED:
            return {
                ...state,
                planError: action.error,
                planMessage: null,
            };
        case actions.CREATE_FAILED_PLAN:
            return {
                ...state,
                loading: true,
                buttonLoading: true,
                planError: null,
                planMessage: null,
            };
        case actions.CREATE_FAILED_PLAN_SUCCESS:
            return {
                ...state,
                loading: false,
                buttonLoading: false,
                planError: null,
                planMessage: action.planMessage,
                planResult: action.planResult,
            };
        case actions.CREATE_FAILED_PLAN_FAILED:
            return {
                ...state,
                loading: false,
                buttonLoading: false,
                planError: action.planError,
                planMessage: null,
            };
        case actions.PACKAGE_ADD_PAGE:
            return {
                ...state,
                packageView: action.packageView
            };

        case actions.GET_TRAFFIC_POLICY_ACTIVE:
            return {
                ...state,
                planError: null,
                planMessage: null,
            };
        case actions.GET_TRAFFIC_POLICY_ACTIVE_SUCCESS:
            return {
                ...state,
                planError: null,
                planMessage: null,
                trafficPolicyResultActive: action.trafficPolicyResultActive
            };
        case actions.GET_TRAFFIC_POLICY_ACTIVE_FAILED:
            return {
                ...state,
                planError: action.planError,
                planMessage: null,
            };
        default:
            return state;
    }
}