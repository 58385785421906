import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData, deleteData, getList } from '../../helpers/fackBackend_Helper';


function* getEsimGoSkuResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/getPlanBundleName', data);
        if (response.response.Status) {
            yield put(actions.getEsimGoSkuSuccess(response.response.PlanBundle));
        } else {
            yield put(actions.getEsimGoSkuFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getEsimGoSkuFailed(error.Message ? error.Message : 'Internal server error.'));
    }
}

function* getPlanListResponse({ payload: { filterPlan } }) {
    try {
        const response = yield call(addData, '/plans', filterPlan);
        if (response.response.Status) {
            yield put(
                actions.getPlanSuccess(
                    response.response.Data
                ));
        } else {
            yield put(actions.getPlanFailed(response.response.Message));
        }

    } catch (error) {
        yield put(actions.getPlanFailed(error));
    }
}

function* addPlanDataResponse({ payload: { data, filterPlan } }) {
    try {
        const response = yield call(addData, '/updatePlan', data);
        if (response.response.Status) {
            const responseList = yield call(addData, '/plans', filterPlan);
            if (responseList.response.Status) {
                yield put(
                    actions.addPlanSuccess(
                        response.response.Message, responseList.response.Data
                    ));
            } else {
                yield put(actions.addPlanFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.addPlanFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.addPlanFailed(error));
    }
}

function* deletePlanDataResponse({ payload: { planId, status, filterPlan } }) {
    try {
        const response = yield call(deleteData, '/deletePlan/' + planId + '/' + status);
        if (response.response.Status) {
            const responseList = yield call(addData, '/plans', filterPlan);
            if (responseList.response.Status) {
                yield put(
                    actions.deletePlanSuccess(
                        response.response.Message, responseList.response.Data
                    ));
            } else {
                yield put(actions.addPlanFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.addPlanFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.addPlanFailed(error));
    }
}

function* getPlanDataResponse({ data }) {
    try {
        const response = yield call(addData, '/shortCode/', data);
        if (response.response.Status) {
            yield put(actions.getPlanDataSuccess(response.response.Data));
        } else {
            yield put(actions.getPlanDataFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getPlanDataFailed(error));
    }
}

function* getPlanDayDataResponse({ data }) {
    try {

        const response = yield call(addData, '/shortCode/', data);
        if (response.response.Status) {

            yield put(actions.getPlanDaySuccess(response.response.Data));
        } else {
            yield put(actions.getPlanDayFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getPlanDayFailed(error));
    }
}
function* getPlanRegionDataResponse({ data }) {
    try {

        const response = yield call(addData, '/shortCode/', data);
        if (response.response.Status) {

            yield put(actions.getPlanRegionSuccess(response.response.Data));
        } else {
            yield put(actions.getPlanRegionFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getPlanRegionFailed(error));
    }
}

function* addToCartPlanResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/addToCart', data);
        if (response.response.Status) {
            yield put(
                actions.addPlanToCartSuccessfull(
                    response.response.Message
                ));
        } else {
            yield put(actions.addPlanToCartFailed(response.response.Message));
        }

    } catch (error) {
        yield put(actions.addPlanToCartFailed(error));
    }
}

function* checkCartDataResponse({ payload: { planId } }) {
    try {
        const response = yield call(getList, '/checkCartData/' + planId);
        if (response.response.Status) {
            yield put(
                actions.checkCartDataSuccessfull(
                    response.response.CartFlag
                ));
        } else {
            yield put(actions.checkCartDataFailed(response.response.Message));
        }

    } catch (error) {
        yield put(actions.checkCartDataFailed(error));
    }
}

function* getCronusInventoryData() {
    try {
        const response = yield call(getList, '/inventory/list');
        if (response.response.Status) {
            yield put(
                actions.getCronusInventoryDataSuccess(
                    response.response.Data.elements
                ));
        } else {
            yield put(actions.getCronusInventoryDataFailed(response.response.Message));
        }

    } catch (error) {
        yield put(actions.getCronusInventoryDataFailed(error));
    }
}

function* getTrafficPolicyResponse() {
    try {
        const response = yield call(getList, '/trafficPolicy');
        if (response.response.Status) {
            yield put(
                actions.getTrafficPolicySuccess(
                    response.response.Data
                ));
        } else {
            yield put(actions.getTrafficPolicyFailed(response.response.Message));
        }

    } catch (error) {
        yield put(actions.getTrafficPolicyFailed(error));
    }
}

function* createPlanPackageResponse({ payload: { packageData, filterPlan } }) {
    try {
        const response = yield call(addData, '/createPlanOnCronus', packageData);
        if (response.response.Status) {
            const responseList = yield call(addData, '/plans', filterPlan);
            if (responseList.response.Status) {
                yield put(actions.createPlanPackageSuccess(response.response.Message, responseList.response.Data));
            } else {
                yield put(actions.createPlanPackageFailed(responseList.response.Message));
            }
        } else {
            yield put(actions.createPlanPackageFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.createPlanPackageFailed(error));
    }
}

function* checkPlanNameResponse({ payload: { planName } }) {
    try {
        const response = yield call(addData, '/planNameCheck/' + planName);
        if (response.response.Status) {
            yield put(
                actions.checkPlanNameSuccess(
                    response.response.Message
                ));
        } else {
            yield put(actions.checkPlanNameFailed(response.response.Message));
        }

    } catch (error) {
        yield put(actions.checkPlanNameFailed(error));
    }
}

function* openPlanModalResponse({ payload: { planID } }) {
    try {
        const response = yield call(getList, '/getPlanByPlanId/' + planID);
        if (response.response.Status) {
            yield put(actions.openPlanModalSuccess(response.response.Data, planID));
        } else {
            // yield put(actions.closePlanModal(response.response.Message));
            yield put(actions.closePlanModal());
        }
    } catch (error) {
        yield put(actions.closePlanModal());
    }
}


function* createFailedPlanResponse({ payload: { planData, aggregator, planId, filterPlan } }) {
    try {
        const response = yield call(addData, '/createFailedPlan/' + `${planId}/` + aggregator, planData);
        if (response.response.Status) {
            const responseList = yield call(addData, '/plans', filterPlan);
            if (responseList.response.Status) {
                yield put(
                    actions.createFailedPlanSuccess(
                        response.response.Data, responseList.response.Data
                    ));
            } else {
                yield put(actions.createFailedPlanFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.createFailedPlanFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.createFailedPlanFailed(error));
    }
}

function* getTrafficPolicyActiveResponse() {
    try {
        const response = yield call(getList, '/trafficPolicyActive');
        if (response.response.Status) {
            yield put(actions.getTrafficPolicyActiveSuccess(response.response.Data));
        } else {
            yield put(actions.getTrafficPolicyActiveFailed(response.response.Message));
        }

    } catch (error) {
        yield put(actions.getTrafficPolicyActiveFailed(error));
    }
}

function* getSimplyEliteSkuResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/getSimplySavePlanBundleName', data);
        if (response.response.Status) {
            yield put(actions.getSimplyEliteSkuSuccess(response.response.PlanBundle));
        } else {
            yield put(actions.getSimplyEliteSkuFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getSimplyEliteSkuFailed(error.Message ? error.Message : 'Internal server error.'));
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.GET_SIMPLY_ELITE_SKU, getSimplyEliteSkuResponse),
        takeEvery(actions.GET_ESIM_GO_SKU, getEsimGoSkuResponse),
        takeEvery(actions.GET_PLAN, getPlanListResponse),
        takeEvery(actions.ADD_PLAN, addPlanDataResponse),
        takeEvery(actions.DELETE_PLAN, deletePlanDataResponse),
        takeEvery(actions.GET_PLAN_DATA, getPlanDataResponse),
        takeEvery(actions.GET_PLAN_DAY, getPlanDayDataResponse),
        takeEvery(actions.GET_PLAN_REGION, getPlanRegionDataResponse),
        takeEvery(actions.ADD_PLAN_TO_CART, addToCartPlanResponse),
        takeEvery(actions.CHECK_CART_DATA, checkCartDataResponse),
        takeEvery(actions.GET_CRONUS_INVENTORY_DATA, getCronusInventoryData),
        takeEvery(actions.GET_TRAFFIC_POLICY, getTrafficPolicyResponse),
        takeEvery(actions.CREATE_PLAN_PACKAGE, createPlanPackageResponse),
        takeEvery(actions.CHECK_PLAN_NAME, checkPlanNameResponse),
        takeEvery(actions.OPEN_PLAN_MODAL, openPlanModalResponse),
        takeEvery(actions.CREATE_FAILED_PLAN, createFailedPlanResponse),
        takeEvery(actions.GET_TRAFFIC_POLICY_ACTIVE, getTrafficPolicyActiveResponse)
    ]);

}
